import { useRouter } from 'next/router';
import Error404 from '../assets/404.svg';
import { Particles } from '../components/Particles';

export default function NotFound() {
  const router = useRouter();

  return (
    <div className="font-roboto">
      <Particles />

      <div className="container grid grid-cols-1 justify-items-center">
        <h2 className="mt-4 md:mt-2 lg:mt-20 text-base md:text-xl lg:text-3xl place-self-center">
          Ops! Page not found
        </h2>
        <Error404 className="-m-20 -mt-32 md:-m-10 md:-mt-20 lg:m-10 lg:-mt-10 transform scale-40 md:scale-60 lg:scale-90" />
        <button className="p-2 px-10 md:p-3 md:px-12 text-gray bg-blue rounded" onClick={() => router.push('/')}>
          Go home
        </button>
      </div>
    </div>
  );
}
